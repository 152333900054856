.FilledOutSections {
  width: 1393px;
  margin-top: 38px;
  margin-left: auto;
  margin-right: auto;
  padding-top: 53px;
  padding-left: 75px;
  padding-right: 75px;
  background-color: white;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 15px;
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: 44px;
    padding-right: 44px;
    padding-bottom: 23px;
    border-bottom: 1px solid #c2c2c2;
    h1 {
      margin: 0;
      font-style: normal;
      font-weight: 500;
      font-size: 35px;
      line-height: 41px;
      color: #3c4b64;
    }
    i {
      display: block;
      width: 26px;
      height: 26px;
      font-size: 0;
      background-image: url(../../../assets/images/penIcon.svg);
      cursor: pointer;
    }
  }
  .container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 1153px;
    margin-left: auto;
    margin-right: auto;
    padding-top: 51px;
    &::after {
      content: '';
      //flex: auto;
      flex-basis: 290px;
    }
    .section {
      display: flex;
      flex-direction: column;
      width: 350px;
      margin-bottom: 83px;
      &.powerFeeds {
        position: relative;
        left: -30px;
      }
      .question {
        height: 52px;
        font-style: normal;
        font-weight: normal;
        font-size: 20px;
        line-height: 23px;
        color: #3c4b64;
      }
      .answer {
        font-style: normal;
        font-weight: bold;
        font-size: 22px;
        line-height: 26px;
        color: #616263;
      }
    }
  }
}
