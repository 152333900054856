.MultipleForms {
  background-color: white;
  max-height: 550px;
  overflow-y: scroll;
  &::-webkit-scrollbar {
    width: 30px;
  }
  
  &::-webkit-scrollbar-track {
    border-radius: 20px;
    width: 5px;
    box-shadow: inset 0 0 10px 10px #fff;
    border: solid 10px transparent;
  }
  
  &::-webkit-scrollbar-thumb {
    width: 5px;
    border-radius: 20px;
    box-shadow: inset 0 0 10px 10px #C4C4C4;
    border: solid 10px transparent;
  }

  .buttonsOuterContainer {
    display: flex;
    justify-content: center;

    .buttonsInnerContainer {
      display: flex;
      justify-content: space-between;
      width: 318px;
      margin-top: 68px;
      margin-bottom: 84px;

      button {
        width: 140px;
        height: 43px;
        font-size: 14px;
        line-height: 16px;
        color: white;
        background-color: #008aff;
        border-radius: 5px;
        border: none;
      }
    }
  }
}