.Header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 128px;
  padding-left: 101px;
  padding-right: 73px;
  background-color: white;

  &.inResultPage {
    height: 109px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  }
  .loginContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 199px;
    .loginLink {
      font-style: normal;
      font-weight: normal;
      font-size: 15px;
      line-height: 18px;
      color: #000000;
      text-decoration: none;
    }
    .signupLink {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 124px;
      height: 49px;
      font-style: normal;
      font-weight: normal;
      font-size: 15px;
      line-height: 18px;
      color: #ffffff;
      background-color: #0165ff;
      border-radius: 24.3364px;
    }
  }
  .logo {
    width: 164px;
    height: 55px;
    background-image: url(../../../assets/images/logo.svg);
    background-repeat: no-repeat;
    background-position: center;
  }
}
