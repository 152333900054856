.ForgotPasswordPage {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 101px;
  .logo {
    margin-bottom: 78px;
  }
  .formContainer {
    width: 402px;
    height: 220px;
    .title {
      margin-bottom: 8px;
      font-size: 32px;
      line-height: 38px;
      color: rgb(90, 90, 90);
    }
    .subTitle {
      margin-bottom: 16px;
      color: rgb(90, 90, 90);
      font-size: 16px;
      line-height: 24px;
    }

    .form {
      .label {
        font-size: 16px;
        line-height: 24px;
        color: rgb(90, 90, 90);
      }
      .formGroup {
        margin-bottom: 16px;
        .errorMessage {
          color: red;
        }
      }
      .submitButton {
        width: 100%;
        height: 48px;
        font-size: 16px;
        line-height: 24px;
        color: white;
        background-color: rgb(0, 138, 255);
        border-radius: 4px;
        border: none;
      }
    }
  }
}
