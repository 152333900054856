.DetailsForm {
  display: flex;
  align-items: center;
  flex-direction: column;
  
  margin: 0 auto;
  background-color: white;
  
  padding-bottom: 54px;
  max-height: 550px;
  overflow-y: scroll;
  &::-webkit-scrollbar {
    width: 30px;
  }
  
  &::-webkit-scrollbar-track {
    border-radius: 20px;
    width: 5px;
    box-shadow: inset 0 0 10px 10px #fff;
    border: solid 10px transparent;
  }
  
  &::-webkit-scrollbar-thumb {
    width: 5px;
    border-radius: 20px;
    box-shadow: inset 0 0 10px 10px #C4C4C4;
    border: solid 10px transparent;
  }
  
  .header {
    width: 100%;
    padding-top: 36px;
    padding-bottom: 29px;
    padding-left: 85px;
    box-sizing: border-box;
    border-bottom: 2px solid rgba(0, 0, 0, 0.12);
    h1 {
      margin: 0;
      padding: 0;
      font-weight: 500;
      font-size: 35px;
      line-height: 41px;
      color: #3c4b64;
    }
  }
  .container {
    max-width: 1020px;
    width: 100%;
    padding-top: 55px;
    .row {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 48px;
      .errBox {
        width: 100%;
        margin-top: .25rem;
        font-size: .875em;
        color: #dc3545;
      }
    
      span {
        font-style: normal;
        font-weight: normal;
        font-size: 20px;
        line-height: 23px;
        color: #3c4b64;
      }

      .textInput {
        width: 445px;
        height: 40px;
        padding-left: 23px;
        color: rgba(0, 0, 0, 0.37);
        border: 0.9px solid #bbbfc4;
        box-sizing: border-box;
        border-radius: 5px;
        padding-bottom: 9px;

        &::placeholder {
          font-style: normal;
          font-weight: normal;
          font-size: 14px;
          color: rgba(0, 0, 0, 0.37);
        }
      }

      .fileUploader {
        display: flex;

        .fileDetails {
          display: flex;
          align-items: center;
          width: 287px;
          height: 40px;
          padding-left: 22px;
          margin-right: 7px;
          border: 0.9px solid #bbbfc4;
          border-radius: 5px;

          span {
            font-size: 14px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            color: rgba(0, 0, 0, 0.37);
          }
        }

        .uploadButton {
          width: 150px;
          height: 40px;
          font-size: 14px;
          line-height: 16px;
          color: white;
          background-color: #008aff;
          border-radius: 5px;
          border: none;
        }
      }
    }
    .buttonsContainer {
      display: flex;
      justify-content: center;
      .formButton {
        box-sizing: border-box;
        width: 140px;
        height: 48px;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 16px;
        color: #ffffff;
        background-color: #008aff;
        border-radius: 5px;
        outline: none;
        border: none;

        &:first-child {
          margin-right: 36px;
        }
      }
    }
  }
}
