.Form {
  display: flex;
  align-items: center;
  flex-direction: column;
  max-width: 1387px;
  padding-top: 65px;
  padding-bottom: 54px;
  margin-top: 20px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 72px;
  background-color: white;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 20px;
  .header {
    width: 100%;
    margin-bottom: 92px;
    h1 {
      margin: 0;
      padding: 0;
      font-family: Lato;
      font-style: normal;
      font-weight: bold;
      font-size: 40px;
      line-height: 46px;
      letter-spacing: 0.2px;
      color: #25282B;
    }
  }
  .container {
    width: 972px;

    .row {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 60px;
      .errBox {
        width: 100%;
        margin-top: .25rem;
        font-size: .875em;
        color: #dc3545;
      }
      span {
        font-style: normal;
        font-weight: normal;
        font-size: 20px;
        line-height: 23px;
        color: #3c4b64;
      }
  
      .textInput {
        width: 445px;
        height: 40px;
        padding-left: 23px;
        color: rgba(0, 0, 0, 0.37);
        border: 0.9px solid #bbbfc4;
        box-sizing: border-box;
        border-radius: 5px;
        padding-bottom: 9px;

        &::placeholder {
          font-style: normal;
          font-weight: normal;
          font-size: 14px;
          color: rgba(0, 0, 0, 0.37);
        }
      }

      .boxError span {
        font-family: Roboto;
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 14px;
        &.errorMsg  {
          color: #FF0000;
          padding-right: 5px;
        }
        &.access {
          color: #008aff;
          text-decoration: underline;
          cursor: pointer;
        }
      }

      .fileUploader {
        display: flex;

        .fileDetails {
          display: flex;
          align-items: center;
          width: 287px;
          height: 40px;
          padding-left: 22px;
          margin-right: 7px;
          border: 0.9px solid #bbbfc4;
          border-radius: 5px;

          span {
            font-size: 14px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            color: rgba(0, 0, 0, 0.37);
          }
        }

        .uploadButton {
          width: 150px;
          height: 40px;
          font-size: 14px;
          line-height: 16px;
          color: white;
          background-color: #008aff;
          border-radius: 5px;
          border: none;
        }
      }

    }
    .buttonsContainer {
      display: flex;
      justify-content: center;
      padding-top: 100px;
      .formButton {
        box-sizing: border-box;
        width: 140px;
        height: 48px;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 16px;
        color: #ffffff;
        background-color: #008aff;
        border-radius: 5px;
        outline: none;
        border: none;
        &.finish {
          background-color: #2eb85c;
        }
        &:first-child {
          margin-right: 36px;
        }
      }
    }
  }
}
