.TypeRadiosContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 60px;
  .typeRadios {
    display: flex;
    justify-content: space-between;
    width: 445px;
    .radio {
      display: flex;
      align-items: center;
      input {
        box-sizing: border-box;
        width: 27px;
        height: 27px;
        border: 3px solid #d8dbe0;
        &:checked {
          border-color: #008aff;
          background-color: transparent;
          background-image: url(../../../../assets/images/checkedRadio.svg);
          background-size: 17px 17px;
          background-position: 2px 2px;
        }
      }
      label {
        position: relative;
        top: 2px;
        margin-left: 21px;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 16px;
        color: #a1a1a1;
      }
    }
  }
  span {
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 23px;
    color: #3c4b64;
  }
}
