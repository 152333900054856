.modalContent {
  padding: 220px 0;
  background: #FFFFFF;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 40px;
  border: none;
}

.boxInner {
  font-family: Roboto;
  .text {
    font-size: 20px;
    line-height: 23px;
    color: #000000;
    text-align: center;
  }

  .boxContent {
    margin-top: 9px;
    background: #0165FF;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 111.5px 0px;

    .container {
      max-width: 550px;
      margin: 0 auto;
      padding-top: 31px;

      .title {
        font-style: normal;
        font-weight: 500;
        font-size: 30px;
        line-height: 35px;
        text-align: center;
        color: #FFFFFF;
      }
      .logosContainer {
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
    }
  }

  .loader {
    margin-top: 25px;
    display: flex;
    justify-content: center;
  }
}
