.ImagePreview {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 242px;
  height: 258px;
  margin-bottom: 51px;
  padding-top: 34px;
  background-color: white;
  box-shadow: 4px 4px 20px rgba(0, 0, 0, 0.4);
  border-radius: 20px;

  .preview {
    width: 183px;
    height: 171px;
    margin-bottom: 13px;
/*     background-image: url('https://images.unsplash.com/photo-1485550409059-9afb054cada4?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=701&q=80');
    background-size: cover;
    background-position: center; */

    .imgItem {
      width: 100%;
    }
  }

  .size {
    font-size: 20px;
    line-height: 23px;
    color: rgba(0, 0, 0, 0.32);
  }

  .deleteIcon {
    position: absolute;
    top: -17px;
    right: -17px;
    display: block;
    width: 35px;
    height: 35px;
    font-size: 0;
    background-image: url(../../../../assets/images/crossIcon.svg);
    background-position: center;
    background-repeat: no-repeat;
    background-color: #ff2b00;
    border-radius: 50%;
    cursor: pointer;
  }
}
