.dialog {
  max-width: 613px;
}

.backDrop {
  z-index: 1060;
}

.SaveSellerFormModal {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 290px;
  box-shadow: 4px 4px 20px rgba(0, 0, 0, 0.25);
  border-radius: 0;
  border: none;

  .header {
    width: 100%;
    margin-bottom: 23px;
    padding-top: 15px;
    padding-left: 17px;
    padding-right: 17px;

    .closeIcon {
      display: block;
      width: 19px;
      height: 19px;
      margin-left: auto;
      font-size: 0;
      background-image: url(../../assets/images/closeIcon.svg);
      background-position: center;
      cursor: pointer;
    }
  }
  .message {
    width: 100%;
    margin-bottom: 68px;
    padding-left: 64px;
    p {
      margin: 0;
      &.title {
        margin-bottom: 7px;
        font-size: 25px;
        line-height: 29px;
      }
      &.subtitle {
        font-size: 18px;
        line-height: 21px;
        color: #928787;
      }
    }
  }

  .buttonsContainer {
    display: flex;
    justify-content: space-between;
    width: 400px;

    button {
      width: 178px;
      height: 54px;
      border: none;

      &.yesButton {
        font-size: 15px;
        line-height: 17px;
        color: white;
        background-color: #0165ff;
      }

      &.noButton {
        font-size: 15px;
        line-height: 18px;
        background-color: white;
        border: 1px solid #cecece;
        box-sizing: border-box;
      }
    }
  }
}
