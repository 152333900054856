$md-radio-checked-color: #008AFF;
$md-radio-border-color: rgba(181, 181, 181, .5);
$md-radio-size: 26px;
$md-radio-checked-size: 14px;

.RadioButton {
  margin: 16px 0;

  input[type="radio"] {
    display: none;
      
    &:checked + label:before {
      border: 6px solid $md-radio-checked-color;   
    }
      
    &:checked + label:after {
      transform: scale(1);
    }
  }
    
  label {
    display: inline-block;
    height:$md-radio-size;
    position: relative;
    padding: 0 ($md-radio-size + 10px);
    margin-bottom: 0;
    cursor: pointer;
    vertical-align: bottom;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 21px;
    color: #584E4E;
      
    &:before, &:after {
      position: absolute;            
      content: '';  
      border-radius: 50%;
      transition: all .1s ease;
      transition-property: transform, border-color;
    }
      
    &:before {
      left: 0;
      top: 0;
      width: $md-radio-size;
      height: $md-radio-size;
      background: $md-radio-border-color;
      box-sizing: border-box;
    }
      
    &:after {
      top: $md-radio-size / 2 - $md-radio-checked-size / 2;
      left: $md-radio-size / 2 - $md-radio-checked-size / 2;
      width:$md-radio-checked-size;
      height:$md-radio-checked-size;
      transform: scale(0);
      background:#fff;
    }
  }
}