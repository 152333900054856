.dataCenter {
  margin: 21px auto 0;
  background: #FFFFFF;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  display: flex;
  align-items: center;
  height: 270px;
  max-width: 1368px;

  .logo {
    max-width: 273px;
    width: 100%;
    height: 100%;
    border-right: 1px solid rgba(0, 0, 0, 0.24);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    img {
      width: 200px;
    }
  }

  .informationContainer {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 55px;

    .box {
      max-width: 500px;
      width: 100%;
      height: 124px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
      .item {
        display: flex;
        align-items: center;
        width: 100%;
        .key {
          max-width: 110px;
          width: 100%;
          font-weight: normal;
          font-size: 20px;
          line-height: 23px;
          color: rgba(0, 0, 0, 0.43);
        }

        .value {
          font-weight: normal;
          font-size: 20px;
          line-height: 23px;
          color: rgba(0, 0, 0, 0.77);
        }
      }
    }
  }
}