.map {
  position: relative;

  .containerAddress {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(0, 0, 0, 0.54);
    height: 183px;
    padding: 20px 47px;
    color: #fff;
    transition: all .2s;

    .title {
      font-weight: 500;
      font-size: 48px;
      line-height: 56px;
    }

    .description {
      font-weight: normal;
      font-size: 25px;
      line-height: 29px;
      max-width: 711px;
    }
  }
}