.inputForm {
  font-family: Lato;
  font-style: normal;
  font-weight: normal;
  max-width: 383px;
  .title {
    font-size: 12px;
    line-height: 14px;
    margin-bottom: 6px;
    color: #727272;
  }

  .textInput {
    padding: 12px 14px;
    border: 0.9px solid #bbbfc4;
    box-sizing: border-box;
    border-radius: 3px;
    font-size: 15px;
    line-height: 18px;
    color: #3C3C3C;
    width: 383px;

    &::placeholder {
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      color: rgba(0, 0, 0, 0.37);
    }
  }
  
}