.AuthHeader {
  position: relative;
  z-index: 1;
  display: flex;
  width: 100%;
  height: 111px;
  padding-left: 101px;
  padding-right: 80px;
  img {
    align-self: flex-end;
  }
  .nav {
    display: flex;
    margin-left: auto;
    padding-top: 68px;
  }
}
