.modal {
  max-width: 1213px;
  margin: 0 auto;
}

.modalContent {
  background: #C4C4C4;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 0;
  border: none;

  .btnClose {
    position: absolute;
    top: -50px;
    right: -10px;
    width: 46px;
    height: 46px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    .iconBtn {
      width: 26px;
      height: 26px;
      background-image: url(../../assets/images/Dashboard/plus.svg);
      background-position: center;
      background-repeat: no-repeat;
      transform: matrix(0.7, 0.79, -0.65, 0.7, 0, 0);
    }
  }
}

