.FeaturesForm {
  display: flex;
  justify-content: center;
  max-width: 1387px;
  padding-top: 36px;
  margin-left: auto;
  margin-right: auto;
  background-color: white;

  max-height: 550px;
  overflow-y: scroll;
  &::-webkit-scrollbar {
    width: 30px;
  }
  
  &::-webkit-scrollbar-track {
    border-radius: 20px;
    width: 5px;
    box-shadow: inset 0 0 10px 10px #fff;
    border: solid 10px transparent;
  }
  
  &::-webkit-scrollbar-thumb {
    width: 5px;
    border-radius: 20px;
    box-shadow: inset 0 0 10px 10px #C4C4C4;
    border: solid 10px transparent;
  }

  .container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    padding-bottom: 39px;

    .button {
      width: 140px;
      height: 43px;
      font-size: 14px;
      line-height: 16px;
      color: white;
      background-color: #008aff;
      border-radius: 5px;
      border: none;
    }

    .header {
      width: 100%;
      padding-bottom: 29px;
      border-bottom: 2px solid rgba(0, 0, 0, 0.12);

      h1 {
        margin: 0;
        margin-left: 85px;
        padding: 0;
        font-style: normal;
        font-weight: 500;
        font-size: 35px;
        line-height: 41px;
        color: #3c4b64;
      }
    }

    .descriptionContainer, .textareaContainer {
      width: 1025px;
      position: relative;
      margin-bottom: 24px;
      padding-top: 40px;

      .heading {
        font-size: 20px;
        line-height: 23px;
        color: #3c4b64;
      }

      textarea {
        resize: none;
        outline: none;
        width: 100%;
        height: 248px;
        margin-top: 17px;
        margin-bottom: 29px;
        padding-top: 10px;
        padding-left: 15px;
        padding-right: 15px;
        border: 1px solid rgba(0, 0, 0, 0.19);
        box-sizing: border-box;
        border-radius: 15px;
      }
    }

    .textareaContainer {
      &::after {
        position: absolute;
        right: 22px;
        bottom: 92px;
        content: 'Limit Character : 120 words';
        font-size: 15px;
        line-height: 18px;
        color: rgba(0, 0, 0, 0.47);
      }
    }

    .featuresList {
      width: 1025px;
      margin-bottom: 70px;
      background-color: white;
      box-shadow: 4px 4px 20px rgba(0, 0, 0, 0.4);

      .itemsContainer {
        max-height: 240px;
        padding-top: 50px;
        padding-left: 51px;
        padding-right: 35px;
        overflow-y: scroll;
        &::-webkit-scrollbar {
          width: 30px;
        }
        
        &::-webkit-scrollbar-track {
          border-radius: 20px;
          width: 5px;
          box-shadow: inset 0 0 10px 10px #fff;
          border: solid 10px transparent;
        }
        
        &::-webkit-scrollbar-thumb {
          width: 5px;
          border-radius: 20px;
          box-shadow: inset 0 0 10px 10px #C4C4C4;
          border: solid 10px transparent;
        }
      }

      ul {
        margin: 0;
        padding: 0;
        list-style: none;

        li {
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-bottom: 50px;
          font-size: 25px;
          line-height: 29px;
          color: #514c4c;

          .removeIcon {
            display: block;
            max-width: 35px;
            min-width: 35px;
            max-height: 35px;
            min-height: 35px;
            font-size: 0;
            background-color: #ff2b00;
            background-image: url(../../../assets/images/crossIcon.svg);
            background-position: center;
            background-repeat: no-repeat;
            border-radius: 50%;
            cursor: pointer;
          }
        }
      }
    }

    .mainButtonsContainer {
      display: flex;
      justify-content: space-between;
      width: 318px;
      padding-bottom: 40px;
    }
  }
}
