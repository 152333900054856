.DataCenterProviders {
  display: flex;
  height: 100vh;

  .boxInner {
    max-width: 712px;
    width: 100%;
    .container{
      max-width: 625px;
      width: 100%;
      margin: 0 auto;
    }
    .header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      height: 128px;
      background-color: white;
      padding: 0 20px;

      .headerInner {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-bottom: 27px;
        border-bottom: 2px solid rgba(0, 0, 0, 0.04);
      }

      .logo {
        width: 164px;
        height: 55px;
        background-image: url(../../assets/images/logo.svg);
        background-repeat: no-repeat;
        background-position: center;
      }
    
      .loginContainer {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 199px;
        .loginLink {
          font-style: normal;
          font-weight: normal;
          font-size: 15px;
          line-height: 18px;
          color: #000000;
          text-decoration: none;
        }
        .signupLink {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 124px;
          height: 49px;
          font-style: normal;
          font-weight: normal;
          font-size: 15px;
          line-height: 18px;
          color: #ffffff;
          background-color: #0165ff;
          border-radius: 24.3364px;
        }
      }
    }

    .providerItems {
      margin-top: 20px;
      max-height: 800px;
      box-sizing: border-box;
      overflow-y: scroll;

      &::-webkit-scrollbar {
        width: 30px;
      }
      
      &::-webkit-scrollbar-track {
        border-radius: 20px;
        width: 5px;
        box-shadow: inset 0 0 10px 10px #fff;
        border: solid 10px transparent;
      }
      
      &::-webkit-scrollbar-thumb {
        width: 5px;
        border-radius: 20px;
        box-shadow: inset 0 0 10px 10px #C4C4C4;
        border: solid 10px transparent;
      }
    }
  }
  .map {
    width: 100%;
  }

  .loader {
    margin-top: 40px;
    display: flex;
    justify-content: center;
    width: 100%;
  }
}