.UserMenu {
  display: flex;
  align-items: center;
  .notification {
    position: relative;
    width: 24px;
    height: 24px;
    background-image: url(../../../assets/images/Dashboard/notification.svg);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  
    .number {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      color: #fff;
      font-family: Lato;
      font-style: normal;
      font-weight: bold;
      font-size: 10px;
      line-height: 12px;
      
      position: absolute;
      width: 16px;
      height: 16px;
      top: -5px;
      right: -8px;
      background-color: #008AFF;
      border: 2px solid #F6F8FB;
      border-radius: 50%;
    }
  }
  
  .user {
    display: flex;
    align-items: center;
    .avatar {
      width: 46px;
      height: 46px;
      margin-left: 16px;
      background-image: url(../../../assets/images/avatar.png);
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;
      border-radius: 50%;
    }
  
    .triangleHide {
      display: none;
  
      &.triangle {
        margin-left: 5px;
        display: block;
        width: 0;
        height: 0;
        border-left: 5px solid transparent;
        border-right: 5px solid transparent;
        border-top: 8px solid #808080;
      }
    }
  }
}
