.SignUpPage {
  position: relative;
  height: 1294px;
  .leftColumn {
    position: absolute;
    left: 0;
    top: 0;
    right: 50%;
    bottom: 0;
    background-color: rgba(4, 122, 246, 0.07);
    background-image: url(../../../assets/images/authBackground.png);
    background-size: 100%;
    background-position: center right;
    background-repeat: no-repeat;
  }
  .rightColumn {
    position: absolute;
    left: 50%;
    top: 0;
    right: 0;
    bottom: 0;
    padding-top: 266px;
    padding-left: 70px;
    padding-right: 63px;

    .wellcomeBlock {
      margin-bottom: 99px;

      h1 {
        margin: 0;
        margin-bottom: 4px;
        font-style: normal;
        font-weight: bold;
        font-size: 48px;
        line-height: 56px;
      }

      p {
        margin: 0;
        padding-left: 6px;
        font-size: 28px;
        line-height: 33px;
        color: rgba(0, 0, 0, 0.38);
      }
    }

    .signUpForm {
      width: 559px;

      .Error {
        position: relative;
        top: -45px;
        color: red;
        text-align: center;
        font-size: 24px;
      }

      .errorMessage {
        position: relative;
        top: -20px;
        color: red;
      }
      .signInputWrapper {
        h5 {
          font-style: normal;
          font-weight: normal;
          font-size: 20px;
          line-height: 23px;
          color: rgba(0, 0, 0, 0.73);
        }
        .signInput {
          margin-bottom: 32px;

          input {
            width: 100%;
            height: 71px;
            padding-left: 24px;
            padding-right: 24px;
            font-size: 32px;
            border: 1px solid rgba(0, 0, 0, 0.25);
            box-sizing: border-box;
            border-radius: 5px;

            &:focus {
              outline: none;
              border: 2px solid #0165ff !important;
            }
          }
        }
      }
      .btnSubmit {
        width: 249px;
        height: 63px;
        margin-top: 15px;
        margin-bottom: 20px;
        font-size: 22px;
        line-height: 26px;
        color: white;
        background-color: #037df8;
        border-radius: 50px;
        border: none;
      }

      .linkBox {
        font-size: 16px;
        line-height: 21px;
        color: rgba(0, 0, 0, 0.38);
        text-align: left;

        .span {
          margin-right: 10px;
        }
      }
    }
  }
}
