.SettingsPage {
  padding: 0 36px;
  font-family: Lato;
  

  .title {
    margin-top: 24px;
    font-style: normal;
    font-weight: bold;
    font-size: 40px;
    line-height: 46px;
    letter-spacing: 0.2px;
    color: #25282B;
  }

  .container {
    margin-top: 25px;
    background: #FFFFFF;
    box-shadow: 4px 4px 20px rgba(0, 0, 0, 0.25);
    min-height: 654px;
    display: flex;

    .sidebar {
      border-right: 1px solid rgba(0, 0, 0, 0.09);
      max-width: 243px;
      width: 100%;
      
      .logoBox {
        .logo {
          width: 152px;
          margin: 47px auto 24px;
        }
        .name {
          text-align: center;
          font-family: Roboto;
          font-style: normal;
          font-weight: bold;
          font-size: 20px;
          line-height: 23px;

          color: #000000;
        }
      }
      .items {
        margin-top: 40px;
        .item {
          padding: 14px 38px;
          font-family: Lato;
          font-style: normal;
          font-weight: bold;
          font-size: 15px;
          line-height: 18px;
          /* identical to box height */
          color: #8A8A8A;
          border-top: 1px solid rgba(0, 0, 0, 0.06);
          cursor: pointer;

          &.active {
            color: #037DF8;
          }
          &:last-child {
            border-bottom: 1px solid rgba(0, 0, 0, 0.06);
          }
        }
      }
    }

    .main {
      width: 100%;
      padding: 35px 49px;
    }
  }
}