.QuestionsFormPage {
  .details {
    width: 1393px;
    margin-top: 37px;
    margin-left: auto;
    margin-right: auto;
    padding-top: 53px;
    padding-left: 75px;
    padding-right: 75px;
    padding-bottom: 92px;
    background-color: white;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 15px;
    .header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-left: 44px;
      padding-right: 44px;
      padding-bottom: 23px;
      border-bottom: 1px solid #c2c2c2;
      h1 {
        margin: 0;
        font-style: normal;
        font-weight: 500;
        font-size: 35px;
        line-height: 41px;
        color: #3c4b64;
      }
      i {
        display: block;
        width: 26px;
        height: 26px;
        font-size: 0;
        background-image: url(../../assets/images/penIcon.svg);
        //cursor: pointer;
      }
    }
    .container {
      padding-top: 50px;
    }
  }
  .finishButtons {
    display: flex;
    justify-content: space-between;
    width: 297px;
    margin-top: 114px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 42px;
    button {
      width: 140px;
      height: 43px;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 16px;
      color: #ffffff;
      border: none;
      border-radius: 5px;
      &.reset {
        background-color: #008aff;
      }
      &.submit {
        background-color: #2eb85c;
      }
    }
  }
}
