.AccountPage {
  display: flex;
  align-items: center;
  max-width: 1387px;
  padding-left: 34px;
  padding-right: 34px;
  padding-top: 34px;
  padding-bottom: 34px;
  margin-top: 20px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 72px;
  background-color: white;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 20px;
  .avatar {
    width: 150px;
    height: 150px;
    background-image: url(../../assets/images/avatar.png);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 50%;
  }
  .userMenu {
    margin-left: 24px;
    h1 {
      font-size: 24px;
      margin-bottom: 20px;
    }
    .buttons {
      display: flex;
      button {
        box-sizing: border-box;
        width: 140px;
        height: 48px;
        margin-right: 14px;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 16px;
        color: #ffffff;
        background-color: #008aff;
        border-radius: 5px;
        outline: none;
        border: none;
      }
    }
  }
}
