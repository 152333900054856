.itemContainer {

  &:first-child .value {
    padding-top: 30px;
  }

  .value {
    padding: 30px 0;
    padding-right: 31px;
  }

  .eyeContainer {
    cursor: pointer;
    padding: 10px 0;
    background-image: url(../../../assets/images/Dashboard/eye.svg);
    background-repeat: no-repeat;
    background-position: center;
    width: 30px;
    height: 16px;
    font-size: 0;
    padding-right: 10px;
  }
  .pencilContainer {
    cursor: pointer;
    padding: 10px 0;
    background-image: url(../../../assets/images/Dashboard/pencil.svg);
    background-repeat: no-repeat;
    background-position: center;
    width: 50px;
    height: 16px;
    font-size: 0;
    padding-right: 10px;
  }
  .basketContainer {
    cursor: pointer;
    padding: 10px 0;
    background-image: url(../../../assets/images/Dashboard/basket.svg);
    background-repeat: no-repeat;
    background-position: center;
    width: 12px;
    height: 14px;
    font-size: 0;
  }
}