.ProgressFlow {
  display: flex;
  justify-content: center;
  height: 166px;
  background-color: white;
  border-radius: 0;
  margin-bottom: 15px;
  .progressBarContainer {
    box-sizing: border-box;
    max-width: 1020px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    &.finished {
      .line {
        background-color: #2eb85c !important;
      }
      .step {
        div {
          background-color: #2eb85c !important;
        }
        span {
          color: #2eb85c !important;
        }
      }
    }
    .line {
      position: relative;
      top: -18px;
      width: 190px;
      height: 4px;
      background-color: #d8dbe0;
      &.filled {
        background-color: #008aff;
      }
    }
    .step {
      position: relative;
      height: 72px;
      display: flex;
      flex-direction: column;
      align-items: center;
      div {
        display: flex;
        justify-content: center;
        align-items: center;
        box-sizing: border-box;
        width: 35px;
        height: 35px;
        font-style: normal;
        font-weight: normal;
        font-size: 18px;
        line-height: 21px;
        color: #a1a1a1;
        border-radius: 50%;
        border: 3px solid #d8dbe0;
      }
      span {
        position: absolute;
        top: 53px;
        font-style: normal;
        font-weight: normal;
        font-size: 18px;
        line-height: 21px;
        color: #a1a1a1;
        white-space: nowrap;
      }
      &.current,
      &.finished {
        div {
          border: none;
          color: white;
          background-color: #008aff;
          box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        }
        span {
          color: #008aff;
        }
      }
      &.finished {
        div {
          font-size: 0;
          background-image: url(../../../assets/images/checkSign.svg);
          background-repeat: no-repeat;
          background-position: center;
        }
      }
    }
  }
}
