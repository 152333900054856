.MatchMakingResultPage {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  padding-bottom: 30px;
  .container {
    max-width: 1300px;
    margin: 0 auto;

    .infoText {
      margin-top: 70px;
      margin-bottom: 43px;
      display: flex;
      .key {
        margin-right: 30px;
        font-size: 28px;
        line-height: 33px;
        text-align: center;
        color: #000000;
      }
      .value {
        font-weight: normal;
        font-size: 25px;
        line-height: 29px;
        text-align: center;
        color: #AFA8A8;
      }
    }
  }
  .itemsContainer{
    height: 680px;
    box-sizing: border-box;
    overflow-y: scroll;

    &::-webkit-scrollbar {
      width: 30px;
    }
    
    &::-webkit-scrollbar-track {
      border-radius: 20px;
      width: 5px;
      box-shadow: inset 0 0 10px 10px #fff;
      border: solid 10px transparent;
    }
    
    &::-webkit-scrollbar-thumb {
      width: 5px;
      border-radius: 20px;
      box-shadow: inset 0 0 10px 10px #C4C4C4;
      border: solid 10px transparent;
    }
  }
}