.DetailsPage {
  font-family: Roboto;
  font-style: normal;
  background: #E5E5E5;
  padding-bottom: 51px;

  &.isFetchingItem {
    background-color: #fff;
  }

  .loader {
    padding-top: 25px;
    display: flex;
    justify-content: center;
  }

  .topFeaturesContainer {
    margin: 21px auto 0;
    display: flex;
    justify-content: space-between;
    max-width: 1368px;

    .topFeatures {
      max-width: 844px;
      width: 100%;
      margin-right: 13px;
      box-sizing: border-box;
      .top {
        background: #0165FF;
        color: #FFFFFF;
        padding: 25px 55px;
        .title {
          font-weight: 500;
          font-size: 25px;
          line-height: 29px;
        }
      }
    }

    .nearbyDataCenters {
      max-width: 511px;
      width: 100%;
      height: 100%;
      .top {
        background: #0165FF;
        color: #FFFFFF;
        padding: 25px 35px 25px 36px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-weight: 500;
        .title {
          font-size: 25px;
          line-height: 29px;
        }

        .seeMore {
          font-size: 15px;
          line-height: 18px;
        }
      }
      .itemsContainer {
        background-color: #fff;
        padding: 18px 50px;
        height: 500px;
        box-sizing: border-box;
        overflow-y: scroll;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);

        &::-webkit-scrollbar {
          width: 30px;
        }
        
        &::-webkit-scrollbar-track {
          border-radius: 20px;
          width: 5px;
          box-shadow: inset 0 0 10px 10px #fff;
          border: solid 10px transparent;
        }
        
        &::-webkit-scrollbar-thumb {
          width: 5px;
          border-radius: 20px;
          box-shadow: inset 0 0 10px 10px #C4C4C4;
          border: solid 10px transparent;
        }

        .item {
          display: flex;
          align-items: center;

          .itemLogo {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            width: 120px;
            height: 95px;
            margin-right: 34px;
          }

          .description {
            .name {
              font-weight: 500;
              font-size: 23px;
              line-height: 27px;
              color: #000000;
            }
            .address {
              font-weight: normal;
              font-size: 15px;
              line-height: 18px;
              color: #BBB3B3;
              margin-top: 5px;
            }
          }
        }

      }
    }
  }

  .ecosystemContainer {
    margin-top: 31px;
    background: #FFFFFF;
    
    .container {
      max-width: 1368px;
      margin: 0 auto;
      display: flex;
      justify-content: space-between;
      padding-top: 33px;
    }

    .items {
      display: flex;
      align-items: center;
      list-style-type: none;
      margin-bottom: 0;
      padding: 0;
      
      .item {
        margin-right: 73px;
        display: block;
        padding-bottom: 30px;
        font-size: 18px;
        line-height: 21px;
        color: #584E4E;
        cursor: pointer;
        border-bottom: 3px solid transparent;
        transition: all .2s;

        &.active {
          color: #037BF7;
          border-bottom: 3px solid #037BF7;
          transition: all .2s;
        }
      }

      .item:last-child {
        margin-right: 10px;
      }
    }

    .dateContainer {
      font-style: normal;
      font-weight: normal;
      font-size: 20px;
      line-height: 23px;
      color: rgba(88, 78, 78, 0.4);
      .span {
        margin-right: 10px;
      }
    }
  }

  .contentContainer {
    max-width: 1368px;
    margin: 21px auto 0;

    .ecosystemBox {
      background: #FFFFFF;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
      .searchContainer {
        padding: 31px 20px 31px 55px;
        border-bottom: 1px solid rgba(0, 0, 0, 0.15);
        display: flex;
        align-items: center;
  
        .search {
          max-width: 424px;
          width: 100%;
          display: flex;
          justify-content: space-between;
          align-items: center;
          border: 1px solid rgba(0, 0, 0, 0.18);
          border-radius: 5px;
          padding: 23px 26px 23px 35px;
          margin-right: 84px;
  
          .inputSearch {
            border: none;
            outline: none;
            font-style: normal;
            font-weight: normal;
            font-size: 20px;
            line-height: 23px;
            color: rgba(88, 78, 78, 0.4);
            width: 100%;
            padding-right: 10px;
          }
        }
  
        .filterContainer {
          display: flex;
          justify-content: space-between;
          align-items: center;
        }
      }
  
      .tableContainer {
        padding-bottom: 76px;
        margin: 35px auto 0;
        padding: 0 25px;
        max-height: 700px;
        box-sizing: border-box;
        overflow-y: scroll;
  
        &::-webkit-scrollbar {
          width: 30px;
        }
        
        &::-webkit-scrollbar-track {
          border-radius: 20px;
          width: 5px;
          box-shadow: inset 0 0 10px 10px #fff;
          border: solid 10px transparent;
        }
        
        &::-webkit-scrollbar-thumb {
          width: 5px;
          border-radius: 20px;
          box-shadow: inset 0 0 10px 10px #C4C4C4;
          border: solid 10px transparent;
        }
  
        .table {
          max-width: 1180px;
          width: 100%;
          margin: 0 auto;
  
          .titleContainer {
            .item {
              font-weight: 600;
              font-size: 23px;
              line-height: 27px;
              padding-bottom: 25px;
              border-bottom: 1px solid rgba(0, 0, 0, 0.15);
              color: rgba(0, 0, 0, 0.61);
            }
            
          }
          .itemContainer {
            cursor: pointer;
            font-style: normal;
            font-weight: normal;
            font-size: 18px;
            line-height: 21px;
            border-bottom: 1px solid rgba(0, 0, 0, 0.15);
            color: rgba(0, 0, 0, 0.59);
            .value {
              padding: 30px 0;
            }

            &:last-child {
              border-bottom: none;
            }
          }
        }
      }
      .loadingContainer {
        display: flex;
        justify-content: center;
        padding: 15px 0 30px;
      }
    }

    .descriptionBox {
      background: #FFFFFF;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
      padding: 45px 47px 52px 64px;
      .descriptionItem + .descriptionItem {
        margin-top: 20px;
      }
    }

    .imagesBox {
      background: #FFFFFF;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
      padding: 36px 0;
      .imgItems {
        max-width: 1143px;
        margin: 0 auto;
        display: flex;
        flex-wrap: wrap;
        gap: 35px 42px;

        .img {
          width: 353px;
        }
      }
    }
    
  }
}