.ContainerForm {
  display: flex;
  align-items: center;
  flex-direction: column;
  
  margin: 0 auto;
  background-color: white;
  padding-top: 0;
  margin-bottom: 19px;
  
  .header {
    width: 100%;
    padding-top: 36px;
    padding-bottom: 29px;
    padding-left: 85px;
    box-sizing: border-box;
    border-bottom: 2px solid rgba(0, 0, 0, 0.12);
    h1 {
      margin: 0;
      padding: 0;
      font-weight: 500;
      font-size: 35px;
      line-height: 41px;
      color: #3c4b64;
    }
  }
  .container {
    max-width: 1020px;
    width: 100%;
    padding-top: 55px;
    .row {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 48px;
  
      span {
        font-style: normal;
        font-weight: normal;
        font-size: 20px;
        line-height: 23px;
        color: #3c4b64;
      }
      .select {
        width: 445px;
        height: 40px;
        color: rgba(0, 0, 0, 0.37);
      }
      .textInput {
        width: 445px;
        height: 40px;
        padding-left: 23px;
        color: rgba(0, 0, 0, 0.37);
        border: 0.9px solid #bbbfc4;
        box-sizing: border-box;
        border-radius: 5px;
        padding-bottom: 9px;

        &::placeholder {
          font-style: normal;
          font-weight: normal;
          font-size: 14px;
          color: rgba(0, 0, 0, 0.37);
        }
      }

      .fileUploader {
        display: flex;

        .fileDetails {
          display: flex;
          align-items: center;
          width: 287px;
          height: 40px;
          padding-left: 22px;
          margin-right: 7px;
          border: 0.9px solid #bbbfc4;
          border-radius: 5px;

          span {
            font-size: 14px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            color: rgba(0, 0, 0, 0.37);
          }
        }

        .uploadButton {
          width: 150px;
          height: 40px;
          font-size: 14px;
          line-height: 16px;
          color: white;
          background-color: #008aff;
          border-radius: 5px;
          border: none;
        }
      }

      .radioContainer {
        display: flex;
        width: 445px;
        .radio {
          display: flex;
          align-items: center;
          &.red {
            input {
              &:checked {
                border-color: #ff0000;
                background-image: url(../../../../assets/images/checkedRadioRed.svg);
              }
            }
          }
          input {
            box-sizing: border-box;
            width: 27px;
            height: 27px;
            border: 3px solid #d8dbe0;

            &:checked {
              border-color: #008aff;
              background-color: transparent;
              background-image: url(../../../../assets/images/checkedRadio.svg);
              background-size: 17px 17px;
              background-position: 2px 2px;
            }
          }
          label {
            position: relative;
            top: 2px;
            margin-left: 21px;
            margin-right: 65px;
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 16px;
            color: #a1a1a1;
          }
        }
      }

      .selectPrefix {
        padding-left: 23px;
        font-family: Roboto;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 16px;
        background-image: url(../../../../assets/images/selectIcon.svg);
        background-size: 9px 12px;
        border: 0.9px solid #bbbfc4;
        box-sizing: border-box;
        border-radius: 5px;
        option {
          color: black;
          &:disabled {
            color: rgba(0, 0, 0, 0.37);
          }
        }
      }
    }
  }
}
