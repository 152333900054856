.Sidebar {
  background: #FFFFFF;
  box-shadow: 4px 0px 16px rgba(16, 30, 115, 0.08);
  min-height: 100vh;
  max-width: 256px;
  width: 100%;

  .logoContainer {
    margin-top: 28px;
    display: flex;
    justify-content: center;
    .logo {
      width: 164px;
      height: 55px;
      background-image: url(../../assets/images/logo.svg);
      background-repeat: no-repeat;
      background-position: center;
    }
  }

  .menuContainer {
    margin-top: 40px;
    background: #FFFFFF;

    .tabItems {
      margin-bottom: 0;
      padding: 0;
      .itemLink {
        display: block;
        text-decoration: none;

        .item {
          display: flex;
          align-items: center;
          font-size: 15px;
          line-height: 18px;
          
          cursor: pointer;
          transition: all .2s;
  
          .borderBox {
            height: 53px;
            width: 5px;
            background-color: transparent;
            &.active {
              background-color: #037DF8;
              border-radius: 0px 2.5px 2.5px 0px;
              transition: all .2s;
            }
          }
  
          .itemTitleContainer {
            padding-left: 30px;
            color: #8A8A8A;
            display: flex;
            align-items: center;
  
            .title {
              padding-left: 20px;
            }
  
            &.topDataCenters {
              .icon {
                background-image: url(../../assets/images/Dashboard/dashboard.svg);
                background-repeat: no-repeat;
                background-position: 0 0;
                width: 16px;
                height: 16px;
              }
  
              &.active {
                color: #037DF8;
                font-weight: bold;
                transition: all .2s;
                .icon {
                  background-image: url(../../assets/images/Dashboard/dashboardActive.svg);
                  background-repeat: no-repeat;
                  background-position: 0 0;
                  width: 16px;
                  height: 16px;
                }
              }
            }
            &.yourDataCenters {
              .icon {
                background-image: url(../../assets/images/Dashboard/yourDataCenter.svg);
                background-repeat: no-repeat;
                background-position: 0 0;
                width: 16px;
                height: 16px;
              }
  
              &.active {
                color: #037DF8;
                font-weight: bold;
                transition: all .2s;
                .icon {
                  background-image: url(../../assets/images/Dashboard/yourDataCenterActive.svg);
  
                }
              }
            }
  
            &.manage {
              .icon {
                background-image: url(../../assets/images/Dashboard/manage.svg);
                background-repeat: no-repeat;
                background-position: 0 0;
                width: 24px;
                height: 24px;
              }
  
              &.active {
                color: #037DF8;
                font-weight: bold;
                transition: all .2s;
                .icon {
                  background-image: url(../../assets/images/Dashboard/manageActive.svg);
                }
              }
            }
            &.analytics {
              .icon {
                background-image: url(../../assets/images/Dashboard/analytics.svg);
                background-repeat: no-repeat;
                background-position: 0 0;
                width: 20px;
                height: 20px;
              }
  
              &.active {
                color: #037DF8;
                font-weight: bold;
                transition: all .2s;
                .icon {
                  background-image: url(../../assets/images/Dashboard/analyticsActive.svg);
                }
              }
            }
            &.support {
              .icon {
                background-image: url(../../assets/images/Dashboard/support.svg);
                background-repeat: no-repeat;
                background-position: 0 0;
                width: 21px;
                height: 21px;
              }
  
              &.active {
                color: #037DF8;
                font-weight: bold;
                transition: all .2s;
                .icon {
                  background-image: url(../../assets/images/Dashboard/supportActive.svg);
                }
              }
            }
            &.help {
              .icon {
                background-image: url(../../assets/images/Dashboard/help.svg);
                background-repeat: no-repeat;
                background-position: 0 0;
                width: 18px;
                height: 18px;
              }
  
              &.active {
                color: #037DF8;
                font-weight: bold;
                transition: all .2s;
                .icon {
                  background-image: url(../../assets/images/Dashboard/helpActive.svg);
                }
              }
            }
          }
        }
        .item:last-child {
          margin-right: 10px;
        }
      }
    }
  }
}