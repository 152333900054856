.FormField {
  margin-bottom: 47px;
  .formFieldContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: 1px solid rgba(0, 0, 0, 0.25);
    box-sizing: border-box;
    border-radius: 5px;
    padding-right: 20px;
  }
  .formControl {
    box-sizing: border-box;
    width: 100%;

    height: 71px;
    padding-left: 24px;
    padding-right: 24px;
    font-size: 32px;

    font-style: normal;
    font-weight: normal;

    border: none;
    background-color: transparent;
    outline: 0;
    box-shadow: none;
  }

  .formLabel {
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 23px;
    color: rgba(0, 0, 0, 0.73);
  }

  .eyeContainer {
    cursor: pointer;
    background-image: url(../../../assets/images/eye.svg);
    background-repeat: no-repeat;
    background-position: center;
    width: 27px;
    height: 19px;
  }
}