.ResetPasswordPage {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 101px;
  .logo {
    margin-bottom: 78px;
  }
  .container {
    width: 402px;
    height: 220px;
    .title {
      text-align: center;
      margin-bottom: 8px;
      font-size: 32px;
      line-height: 38px;
      color: rgb(90, 90, 90);
    }
    .message {
      margin-bottom: 8px;
      padding-left: 50px;
      font-size: 16px;
      line-height: 24px;
      color: red;
      &.success {
        color: green;
      }
    }
  }
}
