.user {
  display: flex;
  align-items: center;
  .username {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    color: #808080;

    &.inProvidersPage {
      display: none;
    }
  }
  .avatar {
    width: 46px;
    height: 46px;
    margin-left: 16px;
    background-image: url(../../../../assets/images/avatar.png);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    border-radius: 50%;
  }

  .triangleHide {
    display: none;

    &.triangle {
      margin-left: 5px;
      display: block;
      width: 0;
      height: 0;
      border-left: 5px solid transparent;
      border-right: 5px solid transparent;
      border-top: 8px solid #808080;
    }
  }
}
