.Form {
  display: flex;
  align-items: center;
  flex-direction: column;
  max-width: 1387px;
  padding-top: 84px;
  padding-bottom: 54px;
  margin-top: 20px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 72px;
  background-color: white;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 20px;
  .header {
    width: 100%;
    margin-bottom: 40px;
    padding-top: 30px;
    padding-left: 200px;
    box-sizing: border-box;
    height: 105px;
    border-bottom: 2px solid rgba(0, 0, 0, 0.12);
    h1 {
      margin: 0;
      padding: 0;
      font-weight: 500;
      font-size: 35px;
      line-height: 41px;
      color: #3c4b64;
    }
  }
  .container {
    width: 972px;

    .row {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 60px;
      .errBox {
        width: 100%;
        margin-top: .25rem;
        font-size: .875em;
        color: #dc3545;
      }
      span {
        font-style: normal;
        font-weight: normal;
        font-size: 20px;
        line-height: 23px;
        color: #3c4b64;
      }
      .select {
        width: 445px;
        height: 40px;
        color: rgba(0, 0, 0, 0.37);
      }
      .textInput {
        width: 445px;
        height: 40px;
        padding-left: 23px;
        color: rgba(0, 0, 0, 0.37);
        border: 0.9px solid #bbbfc4;
        box-sizing: border-box;
        border-radius: 5px;
        padding-bottom: 9px;

        &::placeholder {
          font-style: normal;
          font-weight: normal;
          font-size: 14px;
          color: rgba(0, 0, 0, 0.37);
        }
      }

      .selectPrefix {
        padding-left: 23px;
        font-family: Roboto;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 16px;
        background-image: url(../../../assets/images/selectIcon.svg);
        background-size: 9px 12px;
        border: 0.9px solid #bbbfc4;
        box-sizing: border-box;
        border-radius: 5px;
        option {
          color: black;
          &:disabled {
            color: rgba(0, 0, 0, 0.37);
          }
        }
      }
    }

    .buttonsContainer {
      display: flex;
      justify-content: center;
      .formButton {
        box-sizing: border-box;
        width: 140px;
        height: 48px;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 16px;
        color: #ffffff;
        background-color: #008aff;
        border-radius: 5px;
        outline: none;
        border: none;

        &:first-child {
          margin-right: 36px;
        }
      }
    }
  }
}
