.modal {
  max-width: 600px;
  margin: 0 auto;
}

.modalContent {
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 0;
  border: none;

  .feedbackForm {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    
    margin: 0 auto;
    background-color: white;
    padding-bottom: 30px;
    
    .header {
      width: 100%;
      padding-top: 36px;
      box-sizing: border-box;
      
      h1 {
        margin: 0;
        padding: 0;
        font-weight: 500;
        font-size: 35px;
        line-height: 41px;
        color: #3c4b64;
        text-align: center;
      }
    }
    .container {
      max-width: 1020px;
      width: 100%;
      padding-top: 30px;
      .row {
        margin-bottom: 15px;
        .errBox {
          width: 100%;
          margin-top: .25rem;
          font-size: .875em;
          color: #dc3545;
        }
      
        span {
          font-style: normal;
          font-weight: normal;
          font-size: 20px;
          line-height: 23px;
          color: #3c4b64;
        }
  
        .textInput {
          width: 100%;
          height: 40px;
          padding-left: 23px;
          color: rgba(0, 0, 0, 0.37);
          border: 0.9px solid #bbbfc4;
          box-sizing: border-box;
          border-radius: 5px;
          padding-bottom: 9px;
  
          &::placeholder {
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            color: rgba(0, 0, 0, 0.37);
          }
        }
        .descriptionContainer {
          width: 100%;
          height: 248px;
          position: relative;
          padding-left: 23px;
          color: rgba(0, 0, 0, 0.37);
          border: 0.9px solid #bbbfc4;
          box-sizing: border-box;
          border-radius: 5px;
          padding-bottom: 9px;
          resize: none;
          outline: none;

          &::placeholder {
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            color: rgba(0, 0, 0, 0.37);
          }
        }
      }
      .buttonsContainer {
        display: flex;
        justify-content: center;
        padding-top: 15px;

        .formButton {
          box-sizing: border-box;
          width: 140px;
          height: 40px;
          font-style: normal;
          font-weight: normal;
          font-size: 14px;
          line-height: 16px;
          color: #ffffff;
          background-color: #2eb85c;
          border-radius: 5px;
          outline: none;
          border: none;
  
          &:first-child {
            margin-right: 36px;
          }

          &.cancel {
            background-color: #008aff;
          }
        }
      }
    }
  }

  .btnClose {
    position: absolute;
    top: -50px;
    right: -10px;
    width: 46px;
    height: 46px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    .iconBtn {
      width: 26px;
      height: 26px;
      background-image: url(../../assets/images/Dashboard/plus.svg);
      background-position: center;
      background-repeat: no-repeat;
      transform: matrix(0.7, 0.79, -0.65, 0.7, 0, 0);
    }
  }
}

