.YourDataCenterList {
  padding: 0 36px;
  font-family: Lato;
  

  .title {
    margin-top: 24px;
    font-style: normal;
    font-weight: bold;
    font-size: 40px;
    line-height: 46px;
    letter-spacing: 0.2px;
    color: #25282B;
  }

  .addBtn {
    position: fixed;
    bottom: 30px;
    right: 30px;
    width: 64px;
    height: 64px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: #008AFF;
    box-shadow: 0px 6px 12px rgba(51, 108, 251, 0.16);
    border-radius: 50%;
    cursor: pointer;

    .iconBtn {
      width: 26px;
      height: 26px;
      background-image: url(../../../assets/images/Dashboard/plus.svg);
      background-position: center;
      background-repeat: no-repeat;
    }
  }
}