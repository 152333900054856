.itemsContainer {
  background-color: #fff;
  padding: 28px 25px;
  height: 500px;
  box-sizing: border-box;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  overflow-y: scroll;

  &::-webkit-scrollbar {
    width: 30px;
  }
  
  &::-webkit-scrollbar-track {
    border-radius: 20px;
    width: 5px;
    box-shadow: inset 0 0 10px 10px #fff;
    border: solid 10px transparent;
  }
  
  &::-webkit-scrollbar-thumb {
    width: 5px;
    border-radius: 20px;
    box-shadow: inset 0 0 10px 10px #C4C4C4;
    border: solid 10px transparent;
  }

  .item {
    display: flex;
    align-items: center;
    padding: 25px 30px;
    max-width: 778px;
    width: 100%;
    margin: 0 auto;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  }

  .item:first-child {
    padding: 0 30px 25px;
  }
  .item:last-child {
    border-bottom: none;
  }

  .checkmark {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-right: 47px;

    .checkmarkInner {
      display: flex;
      justify-content: center;
      align-items: center;
      box-sizing: border-box;
      width: 35px;
      height: 35px;
      font-style: normal;
      font-weight: normal;
      font-size: 18px;
      line-height: 21px;
      border-radius: 50%;
      background-image: url(../../../assets/images/checkSign.svg);
      background-repeat: no-repeat;
      background-position: center;
      border: none;
      color: white;
      background-color: #008aff;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    }
  }

  .description {
    font-weight: normal;
    font-size: 20px;
    line-height: 23px;
    color: #514C4C;
  }
}