.MatchMakingItem {
  background: #FFFFFF;
  border: 1px solid #CBCBCB;
  box-sizing: border-box;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 20px;
  position: relative;

  & + .MatchMakingItem {
    margin: 24px 0;
  }

  .verified {
    background-image: url(../../../assets/images/verified.svg);
    background-repeat: no-repeat;
    background-position: center;
    width: 72px;
    height: 72px;
    position: absolute;
    left: 0;
    top: 0;
  }

  .itemContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 224px;
    padding: 0 65px 0 43px;
    cursor: pointer;
    
    .boxInner {
      display: flex;
      align-items: center;

      .itemLogo {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 120px;
        height: 95px;
        margin-right: 34px;
        img {
          width: 100%;
        }
      }

      .description {
        .name {
          font-weight: 500;
          font-size: 45px;
          line-height: 53px;
          color: #000000;
        }
        .address {
          font-size: 22px;
          line-height: 26px;
          color: #BBB3B3;
          margin-top: 5px;
        }
      }
    }

    .contactBtn {
      background: #037DF8;
      border-radius: 50px;
      font-size: 15px;
      line-height: 18px;
      color: #FFFFFF;
      padding: 21px 55px;
      cursor: pointer;
    }
  }

  .descriptionBlock {
    padding: 0 65px 64px 79px;
    margin: 0 auto;
    .itemsContainer {
      background-color: #fff;
      display: flex;
      flex-wrap: wrap;

      .item {
        max-width: 570px;
        width: 100%;
        display: flex;
        align-items: center;
        margin-bottom: 32px;
      }

      .checkmark {
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-right: 47px;

        .checkmarkInner {
          display: flex;
          justify-content: center;
          align-items: center;
          box-sizing: border-box;
          width: 35px;
          height: 35px;
          font-style: normal;
          font-weight: normal;
          font-size: 18px;
          line-height: 21px;
          border-radius: 50%;
          background-image: url(../../../assets/images/checkSign.svg);
          background-repeat: no-repeat;
          background-position: center;
          border: none;
          color: white;
          background-color: #008aff;
          box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        }
      }

      .description {
        font-weight: normal;
        font-size: 20px;
        line-height: 23px;
        color: #514C4C;
      }
    }
  }
}