.specsBox {
  display: flex;
  justify-content: space-between;

  .box {
    max-width: 660px;
    width: 100%;
    & + .box {
      margin-left: 39px;
    }
    .boxItem {
      padding: 27px 29px;
      background: #FFFFFF;
      box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.25);
      & + .boxItem {
        margin-top: 25px;
      }
      .title {
        font-weight: 600;
        font-size: 23px;
        line-height: 27px;
        color: rgba(0, 0, 0, 0.61);
        padding-bottom: 25px;
        border-bottom: 1px solid rgba(0, 0, 0, 0.15);
      }
      .option {
        padding: 28px 0;
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-bottom: 1px solid rgba(0, 0, 0, 0.12);

        &:last-child {
          border-bottom: none;
        }

        .key {
          font-style: normal;
          font-weight: normal;
          font-size: 18px;
          line-height: 21px;
          color: rgba(0, 0, 0, 0.59);
        }
        .value {
          font-style: normal;
          font-weight: normal;
          font-size: 18px;
          line-height: 21px;
          color: #000000;
          width: 190px;
          text-align: center;

          .checkmark {
            position: relative;
            display: flex;
            flex-direction: column;
            align-items: center;

            .checkmarkInner {
              display: flex;
              justify-content: center;
              align-items: center;
              box-sizing: border-box;
              width: 35px;
              height: 35px;
              font-style: normal;
              font-weight: normal;
              font-size: 18px;
              line-height: 21px;
              border-radius: 50%;
              background-repeat: no-repeat;
              background-position: center;
              border: none;
              color: white;
            }

            &.checkmarkChecked {
              .checkmarkInner {
                background-image: url(../../../assets/images/checkSign.svg);
                background-color: #008aff;
                box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
              }
            }

            &.checkmarkCancel {
              .checkmarkInner {
                background-image: url(../../../assets/images/checkSignCancel.svg);
                background-color: #FF2B00;
              }
            }
          }
        }
      }
    }
   
  }
}