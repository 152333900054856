.ProviderItem {
  background: #FFFFFF;
  box-shadow: 4px 4px 20px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  margin-top: 10px;
  margin-bottom: 20px;

  & + .ProviderItem {
    margin: 24px 0;
  }

  .itemContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 149px;
    padding: 0 30px;
    cursor: pointer;
    background: #FFFFFF;
    
    
    &.isOpenBlock {
      box-shadow: 0 1px 10px -1px rgba(0, 0, 0, 0.25);
    }
    
    .boxInner {
      display: flex;
      align-items: center;

      .itemLogo {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 120px;
        height: 95px;
        margin-right: 42px;
        img {
          width: 100%;
        }
      }

      .description {
        .name {
          font-weight: 500;
          font-size: 28px;
          line-height: 33px;

          color: #000000;
        }
        .address {
          font-size: 20px;
          line-height: 23px;

          color: #BBB3B3;
          margin-top: 10px;
        }
      }
    }

    .viewBtn {
      font-size: 14px;
      line-height: 16px;
      text-align: center;
      color: #BBB3B3;
      display: flex;
      align-items: center;

      .text{
        margin-right: 9px;
      }

      .arrow {
        background-image: url(../../../assets/images/arrow.svg);
        background-repeat: no-repeat;
        background-position: center;
        height: 14px;
        width: 25px;
        transform: rotate(180deg);

        &.isOpen {
          transform: rotate(360deg);
        }
      }
    }
  }

  .locationBlock {
    padding-top: 20px;
    margin: 0 auto;

    .itemsContainer {
      padding: 0 10px 0 45px;
      background-color: #fff;
      max-height: 480px;
      box-sizing: border-box;
      overflow-y: scroll;

      &::-webkit-scrollbar {
        width: 30px;
      }
      
      &::-webkit-scrollbar-track {
        border-radius: 20px;
        width: 5px;
        box-shadow: inset 0 0 10px 10px #fff;
        border: solid 10px transparent;
      }
      
      &::-webkit-scrollbar-thumb {
        width: 5px;
        border-radius: 20px;
        box-shadow: inset 0 0 10px 10px #C4C4C4;
        border: solid 10px transparent;
      }

      .item {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 32px;
        cursor: pointer;

        .key {
          font-style: normal;
          font-weight: normal;
          font-size: 18px;
          line-height: 21px;
          color: #000000;
          margin-right: 20px;
        }

        .location {
          font-size: 18px;
          line-height: 21px;
          color: rgba(0, 0, 0, 0.36);
        }
      }
    }
  }
}