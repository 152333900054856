.Account {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  .title {
    font-family: Lato;
    font-style: normal;
    font-weight: bold;
    font-size: 28px;
    line-height: 46px;
    letter-spacing: 0.2px;
    color: #25282B;
    margin-bottom: 38px;
  }
  .form {
    width: 100%;
    .row {
      margin-top: 39px;
      max-width: 850px;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    .inputForm {
      font-family: Lato;
      font-style: normal;
      font-weight: normal;
      max-width: 383px;
      .label {
        font-size: 12px;
        line-height: 14px;
        margin-bottom: 6px;
        color: #727272;
      }
    }
    .fileUploader {
      display: flex;

      .fileDetails {
        display: flex;
        align-items: center;
        width: 383px;
        height: 43px;
        padding-left: 22px;
        padding-right: 22px;
        border: 0.9px solid #bbbfc4;
        border-radius: 3px;

        .valueBox {
          display: flex;
          align-items: center;
          background: #EBEBEB;
          padding: 5px 6px 5px 13px;


          .value {
            margin-right: 10px;
          }

          .removeBtn {
            cursor: pointer;
            background-image: url(../../../assets/images/Dashboard/closeUpload.svg);
            background-repeat: no-repeat;
            background-position: center;
            width: 17px;
            height: 17px;
          }

          &.empty {
            background-color: transparent;
            padding: 0;
            .value {
              font-family: Roboto;
              font-style: normal;
              font-weight: normal;
              font-size: 14px;
              line-height: 16px;
              color: rgba(0, 0, 0, 0.37);
              
            }

            .removeBtn {
              display: none;
            }
          }
        }

        span {
          font-size: 14px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          color: rgba(0, 0, 0, 0.37);
        }
      }
    }
  }
  .btnsAction {
    margin: 0 auto;
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    .button {
      width: 140px;
      border: none;
      outline: none;
      border-radius: 5px;
      font-size: 14px;
      line-height: 16px;
      padding: 13px 0;
      &.updateBtn {
        background-color: #008AFF;
        color: #fff;
        margin-right: 38px;
      }
      &.cancelBtn {
        background: #E9E9E9;
        border: 1px solid rgba(0, 0, 0, 0.04);
        box-sizing: border-box;
        color: #787878;
      }
    }
  }
}