.dialog {
  max-width: 639px;
  box-sizing: border-box;
}

.SelectAccountModal {
  position: relative;
  padding-top: 26px;
  padding-left: 43px;
  padding-right: 43px;
  box-shadow: 4px 4px 20px rgba(0, 0, 0, 0.25);
  border-radius: 15px;
  border: none;

  .closeIcon {
    position: absolute;
    top: 27px;
    right: 23px;
    display: block;
    width: 19px;
    height: 19px;
    font-size: 0;
    background-image: url(../../assets/images/closeIconSmall.svg);
  }

  .message {
    margin-bottom: 156px;

    .title {
      margin: 0;
      font-style: normal;
      font-weight: bold;
      font-size: 48px;
      line-height: 56px;
    }

    .subtitle {
      margin: 0;
      font-size: 28px;
      line-height: 33px;
      color: rgba(0, 0, 0, 0.38);
    }
  }

  .buttonsContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 210px;

    button {
      width: 249px;
      height: 63px;
      background: #037df8;
      font-size: 22px;
      line-height: 26px;
      color: white;
      border-radius: 50px;
      border: none;
    }

    span {
      margin-top: 50px;
      margin-bottom: 50px;
      font-size: 20px;
      line-height: 23px;
      color: rgba(0, 0, 0, 0.38);
    }
  }
}
