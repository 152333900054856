.tableContainer {
  margin: 35px auto 0;
  padding: 29px 40px 37px;
  max-height: 677px;
  box-sizing: border-box;
  background: #FFFFFF;
  box-shadow: 0px 12px 26px rgba(16, 30, 115, 0.06);
  border-radius: 8px;


  .table {
    width: 100%;
    font-family: Lato;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 0.1px;
    color: #25282B;

    .tHead{
      border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    }

    .titleContainer {
      max-width: 600px;
      margin: 0 auto;
      .item {
        padding-bottom: 20px;
      }
    }
  }

  .pagination {
    margin-top: 30px;
    .paginationBttns {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      margin: 0 auto;
      .arrowBox {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 32px;
        height: 32px;
        border: 1px solid #E8E8E8;
        box-sizing: border-box;
        border-radius: 4px;
        
      }
      li {
        margin-right: 4px;
        width: 32px;
        height: 32px;
        list-style-type: none;
        border: 1px solid #E8E8E8;
        box-sizing: border-box;
        border-radius: 4px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        cursor: pointer;

        
        .arrowLeftBox {
          background-image: url(../../../assets/images/Dashboard/arrowLeft.svg);
          background-repeat: no-repeat;
          background-position: center;

          &.disabledArrowLeft {
            background-image: url(../../../assets/images/Dashboard/arrowLeftDisabled.svg);
            background-repeat: no-repeat;
            background-position: center;
          }
        }
        .arrowRightBox {
          background-image: url(../../../assets/images/Dashboard/arrowRight.svg);
          background-repeat: no-repeat;
          background-position: center;

          &.disabledArrowRight {
            background-image: url(../../../assets/images/Dashboard/arrowRightDisabled.svg);
            background-repeat: no-repeat;
            background-position: center;
          }
        }
      }
      
      a {
        font-family: Lato;
        font-style: normal;
        font-weight: normal;
        font-size: 13px;
        line-height: 16px;
        padding: 8px;
        display: block;
        font-weight: 400;
        color: #25282B;
      }
      .previous,
      .next {
        border: none;
        cursor: pointer;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
      }
      .paginationActive {
        background-color: #008AFF;
        a {
          color: #f4f4f4;
        }
      }
    }
  }
}