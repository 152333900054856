.form {
  .Error {
    color: red;
    text-align: center;
    padding-bottom: 20px;
    font-size: 20px;
  }

  .container {
    display: flex;
    flex-wrap: wrap;
  }

  .inputGroup {
    min-width: 445px;
    height: 76px;
    margin-right: 154px;
    margin-bottom: 40px;
    .errorMessage {
      color: red;
    }
    .label {
      margin-bottom: 13px;
      font-style: normal;
      font-weight: normal;
      font-size: 20px;
      line-height: 23px;
      color: #3c4b64;
    }
    .input {
      width: 445px;
      height: 40px;
      border: 0.9px solid #bbbfc4;
      box-sizing: border-box;
      border-radius: 5px;
      &:invalid {
        border-color: red;
      }
    }
  }
}