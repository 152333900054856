.PhoneField {
  .phoneContainer {
    width: 445px;
    height: 40px;
    padding-left: 23px;
    color: rgba(0, 0, 0, 0.37);
    border: 0.9px solid #bbbfc4;
    box-sizing: border-box;
    border-radius: 5px;
    outline: none;
    display: block;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    background-color: #fff;
    background-clip: padding-box;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;

    &::placeholder {
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      color: rgba(0, 0, 0, 0.37);
    }

    &:focus {
      box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, .25);
    }

    &.isInvalid {
      padding-right: calc(1.5em + .75rem);
      background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23dc3545'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
      background-repeat: no-repeat;
      background-position: right calc(.375em + .1875rem) center;
      background-size: calc(.75em + .375rem) calc(.75em + .375rem);

      &:focus {
        box-shadow: 0 0 0 0.25rem rgba(220, 53, 69, .25);
      }
    }
  }
}