.ImageUploaderInModalForm {
  display: flex;
  justify-content: center;
  padding-top: 55px;
  padding-bottom: 57px;
  margin-top: 27px;
  margin-left: auto;
  margin-right: auto;
  background-color: white;
  max-height: 550px;
  overflow-y: scroll;
  &::-webkit-scrollbar {
    width: 30px;
  }
  
  &::-webkit-scrollbar-track {
    border-radius: 20px;
    width: 5px;
    box-shadow: inset 0 0 10px 10px #fff;
    border: solid 10px transparent;
  }
  
  &::-webkit-scrollbar-thumb {
    width: 5px;
    border-radius: 20px;
    box-shadow: inset 0 0 10px 10px #C4C4C4;
    border: solid 10px transparent;
  }

  .container {

    .header {
      display: flex;
      flex-direction: column;
      align-items: center;
      .title {
        margin-bottom: 5px;
        font-size: 40px;
        line-height: 47px;
        color: #000000;
      }
      .subtitle {
        margin-bottom: 63px;
        font-size: 20px;
        line-height: 23px;
        color: rgba(0, 0, 0, 0.5);
      }
    }

    .uploader {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 965px;
      max-height: 506px;
      height: 100%;
      margin-bottom: 38px;
      box-sizing: border-box;
      background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='20' ry='20' stroke='%23008AFF' stroke-width='4' stroke-dasharray='14%2c 14' stroke-dashoffset='0' stroke-linecap='round'/%3e%3c/svg%3e");
      border-radius: 20px;
      img {
        margin-bottom: 36px;
      }
      p {
        font-size: 25px;
        line-height: 29px;
        color: rgba(0, 0, 0, 0.32);
      }
    }

    .imagesList {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      width: 827px;
      margin-bottom: 73px;
      &::after {
        content: '';
        flex: 0 0 33%;
        flex-basis: 242px;
      }
    }

    .buttonsContainer {
      display: flex;
      justify-content: space-between;
      width: 318px;
      margin: 0 auto;
      padding-bottom: 57px;
      button {
        width: 140px;
        height: 43px;
        font-size: 14px;
        line-height: 16px;
        color: white;
        background-color: #008aff;
        border-radius: 5px;
        border: none;
      }
    }
  }
}
