.buttonsFieldContainer {
  width: 445px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;

  

  .formButton {
    box-sizing: border-box;
    width: 153px;
    height: 40px;
    background-color: #E2E2E2;
    color: rgba(0, 0, 0, 0.37);
    border-radius: 5px;
    outline: none;
    border: none;
    &.active {
      border: 1px solid #008aff;
      background-color: #fff;
      color: #008aff;
    }
    &:first-child {
      margin-right: 36px;
    }
  }
}